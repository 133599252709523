import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProviders } from './providers/AppProviders';
import { SessionCheck } from './SessionCheck';

const MobileLayout = React.lazy(async () => import('./views/layout/mobile-layout'));

const App = () => {
  return (
    <BrowserRouter>
      <SessionCheck>
        <AppProviders>
          <Routes>
            <Route path="/*" element={<MobileLayout />} />
          </Routes>
        </AppProviders>
      </SessionCheck>
    </BrowserRouter>
  );
};

export default App;
